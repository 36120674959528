import p13nUtils from 'utils/localStorage/P13n';
import cookieUtils from 'utils/Cookies';
import analyticsUtils from 'analytics/utils';
import Empty from 'constants/empty';
import { RUPEX_FORM_RESPONSE_TYPES } from 'constants/personalization';

const { SUBMIT } = RUPEX_FORM_RESPONSE_TYPES;

const { getPersonalizationCache, setPersonalizationCache } = p13nUtils;

const checkDataToDisplay = (cache, personalization) => {
    if (cache) {
        if (personalization?.activeAbTestIds?.indexOf(cache.p13n.abTestId) !== -1) {
            return cache;
        } else if (!personalization?.activeAbTestIds) {
            return cache;
        } else if (!cache.p13n?.abTestId && cache.variationData) {
            return cache;
        }
    }

    return null;
};

const getPersonalizedComponent = (personalization = {}, user, p13n, isBannerComponent) => {
    const prvCookie = cookieUtils.read(cookieUtils.KEYS.P13N_PRV);

    if (prvCookie && p13n.data?.length > 0) {
        return p13n.data.find(item => item.context === personalization?.context);
    } else if (user.isInitialized && personalization?.isEnabled && p13n.isInitialized) {
        const cachedData = getPersonalizationCache(personalization?.context);
        const p13nData = checkDataToDisplay(cachedData, personalization);

        if (p13nData) {
            if (isBannerComponent) {
                digitalData.page.attributes.contentfulPersonalization = analyticsUtils.getContentfulPersonalization(p13nData);
            }

            return p13nData;
        }
    }

    return [];
};

const getMegaNavPersonalizedComponent = (p13n, personalization, user) => {
    let personalizedComponent = Empty.Array;
    const cachedData = getPersonalizationCache(personalization?.context);

    if (cachedData && !user.isAnonymous) {
        personalizedComponent = cachedData;
    } else {
        if (!Sephora.isNodeRender && Sephora.Util.InflatorComps.services.loadEvents.HydrationFinished) {
            const prvCookie = cookieUtils.read(cookieUtils.KEYS.P13N_PRV);

            if (prvCookie && p13n.data?.length > 0) {
                personalizedComponent = p13n.data.find(item => item.context === personalization?.context) || Empty.Array;
            } else if (p13n.headData?.length) {
                setPersonalizationCache(p13n.headData);
                const headItemData = p13n.headData.find(item => (item.p13n?.context || item.context) === personalization?.context);
                personalizedComponent = checkDataToDisplay(headItemData, personalization);
            } else if (user.isAnonymous) {
                personalizedComponent = Empty.Array;
            } else {
                personalizedComponent = getPersonalizedComponent(personalization, user, p13n, false);
            }
        }
    }

    return personalizedComponent;
};

const getCustomerAttributes = (customer360, previewDateTime, viewActive, includeOOS, atgId) => {
    const { country, language } = Sephora.renderQueryParams;
    const showAsset = viewActive ? 'active' : 'all';
    let customerObj = customer360;

    if (!customerObj) {
        customerObj = {
            customer360Attribute: {},
            customerPropensity: {},
            offerDetails: {}
        };
    }

    return {
        customerFullObject: {
            ...customerObj
        },
        channel: 'web',
        locale: `${language}-${country}`,
        isPreview: true,
        previewDateTime: previewDateTime.substring(0, previewDateTime.indexOf('T')),
        showAsset,
        includeOOS,
        // eslint-disable-next-line camelcase
        atg_id: atgId
    };
};

const postRupexFormSubmit = () => {
    window.postMessage({ type: SUBMIT, data: true }, '*');
};

export default {
    checkDataToDisplay,
    getPersonalizedComponent,
    getMegaNavPersonalizedComponent,
    getCustomerAttributes,
    postRupexFormSubmit
};

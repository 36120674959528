/* eslint-disable camelcase */
/* eslint-disable complexity */
import React from 'react';
import PropTypes from 'prop-types';
import {
    CONSTRUCTOR_PODS, RESULTS_COUNT, PRICE_RANGE, BRANDS
} from 'constants/constructorConstants';
import constants from 'constants/content';
import { Fragment } from 'react';
import { wrapComponent } from 'utils/framework';
import BaseClass from 'components/BaseClass';
import { getCmsComponent } from 'constants/cmsComponentsMapping';
import ConstructorCarousel from 'components/ConstructorCarousel';
import Empty from 'constants/empty';
import LazyLoad from 'components/LazyLoad';
import PageRenderReport from 'components/PageRenderReport/PageRenderReport';
import cmsComponentActions from 'actions/CmsComponentActions';

const {
    COMPONENT_TYPES: {
        BANNER_LIST,
        BANNER,
        COPY,
        DIVIDER,
        PRODUCT_LIST,
        PROMOTION_LIST,
        RECAP,
        REWARD_LIST,
        SECTION_HEADING,
        SOFT_LINKS,
        SMS_OPTIN,
        SECTION,
        CUSTOM_RENDERING,
        UGC_WIDGET,
        LOVES_LIST
    },
    CONTEXTS,
    COMPONENT_SPACING
} = constants;

class ComponentList extends BaseClass {
    componentDidMount() {
        const { page, items } = this.props;
        cmsComponentActions.updateComsComponentPageData({
            page,
            items
        });
    }

    render() {
        const {
            context,
            enablePageRenderTracking,
            items,
            page,
            trackingCount,
            removeFirstItemMargin,
            removeLastItemMargin,
            trackSoftLink,
            customStyles,
            customCardSize,
            params
        } = this.props;

        let renderedComponentsCounter = 0;
        let lazyLoadEnabled = false;
        let numberOfComponentsToRenderWithoutLazyLoad = 0;

        return (
            <>
                {items.map((item, index) => {
                    const constructorItem = {};
                    const isLastItem = index === items.length - 1;

                    switch (item.type) {
                        case BANNER:
                            item.shouldTriggerImpression = true;

                            if (!isLastItem && items[index + 1].type === SECTION_HEADING) {
                                item.marginBottom = null;
                            }

                            break;
                        case BANNER_LIST:
                            if (!isLastItem && items[index + 1].type === BANNER) {
                                item.marginBottom = COMPONENT_SPACING.SM;
                            }

                            break;
                        case SECTION_HEADING:
                            if (!isLastItem && items[index + 1]) {
                                items[index + 1].marginTop = null;
                            }

                            break;
                        case PRODUCT_LIST:
                            if (item.action?.targetUrl === '/shopping-list') {
                                item.type = LOVES_LIST;
                            }

                            break;
                        default:
                            break;
                    }

                    if (!isLastItem && items[index + 1].type === DIVIDER) {
                        item.marginBottom = null;
                    }

                    if (removeFirstItemMargin && index === 0) {
                        item.marginTop = null;
                    }

                    if (removeLastItemMargin && isLastItem) {
                        item.marginBottom = null;
                    }

                    const enableRenderTracking = enablePageRenderTracking && index < trackingCount;
                    const { type, features, ...restItemProps } = constructorItem.type ? constructorItem : item;
                    let ComponentToRender = getCmsComponent({ type, features });

                    if (!ComponentToRender) {
                        return null;
                    }

                    let propsToRender = {};

                    function preparePropsToRender(props, componentToRender) {
                        const commonPropsToRender = {
                            context,
                            enablePageRenderTracking: enableRenderTracking,
                            page,
                            customStyles,
                            customCardSize,
                            ...restItemProps
                        };

                        if (componentToRender) {
                            ComponentToRender = componentToRender;
                        }

                        propsToRender = {
                            ...commonPropsToRender,
                            ...props
                        };
                    }

                    if (item.ctorPodId) {
                        const podIdConfigurations = {
                            [CONSTRUCTOR_PODS.RFY_BASKET]: {
                                condition: params?.itemIds?.length > 0,
                                config: {
                                    params,
                                    podId: CONSTRUCTOR_PODS.RFY_BASKET
                                }
                            },
                            [CONSTRUCTOR_PODS.BOPIS_BASKET]: {
                                condition: params?.itemIds?.length > 0,
                                config: {
                                    params,
                                    podId: CONSTRUCTOR_PODS.BOPIS_BASKET
                                }
                            },
                            [CONSTRUCTOR_PODS.UNDER_20_LOYALTY]: {
                                config: {
                                    params: {
                                        filters: {
                                            Price: PRICE_RANGE.UNDER_TWENTY
                                        }
                                    },
                                    podId: CONSTRUCTOR_PODS.UNDER_20_LOYALTY
                                }
                            },
                            [CONSTRUCTOR_PODS.SALE_LOYALTY]: {
                                config: {
                                    params: {
                                        filters: {
                                            on_sale: true
                                        }
                                    },
                                    podId: CONSTRUCTOR_PODS.SALE_LOYALTY
                                }
                            },
                            [CONSTRUCTOR_PODS.BASKET_QUICK_ADDS]: {
                                condition: params?.itemIds?.length > 0,
                                config: {
                                    params: {
                                        ...params,
                                        numResults: RESULTS_COUNT.BASKET_QUICK_ADDS,
                                        filters: {
                                            Price: PRICE_RANGE.UNDER_FIFTEEN,
                                            Brand: BRANDS.SEPHORA_COLLECTION
                                        }
                                    },
                                    podId: CONSTRUCTOR_PODS.BASKET_QUICK_ADDS
                                }
                            },
                            [CONSTRUCTOR_PODS.AUTO_REPLENISH_SEPHORA_COLLECTION]: {
                                config: {
                                    params: {
                                        filters: {
                                            Brand: BRANDS.SEPHORA_COLLECTION
                                        }
                                    },
                                    podId: CONSTRUCTOR_PODS.AUTO_REPLENISH_SEPHORA_COLLECTION
                                }
                            },
                            default: {
                                config: {
                                    params: {},
                                    podId: item.ctorPodId
                                }
                            }
                        };

                        const podIdKey = item.ctorPodId in podIdConfigurations ? item.ctorPodId : 'default';
                        const { condition = true, config } = podIdConfigurations[podIdKey];

                        if (condition) {
                            preparePropsToRender(config, ConstructorCarousel);
                        }
                    } else {
                        preparePropsToRender({
                            ...restItemProps,
                            trackSoftLink
                        });
                    }

                    // Strategy for enabling LazyLoad
                    switch (page) {
                        // On home page we start wrapping components with LazyLoad after rendering 2 components.
                        // Also we skip counting the following components: DIVIDER, ANCHOR.
                        case 'home':
                        case 'offers': {
                            lazyLoadEnabled = true;

                            // 2 components for Home page
                            // 3 components for Offers page - to pre-render the first 3 before the fold
                            numberOfComponentsToRenderWithoutLazyLoad = page === 'home' ? 2 : 3;

                            switch (type) {
                                case BANNER_LIST:
                                case BANNER:
                                case COPY:
                                case PRODUCT_LIST:
                                case PROMOTION_LIST:
                                case RECAP:
                                case REWARD_LIST:
                                case SECTION_HEADING:
                                case SOFT_LINKS:
                                case SMS_OPTIN:
                                case SECTION:
                                case CUSTOM_RENDERING: {
                                    renderedComponentsCounter++;

                                    break;
                                }
                                case UGC_WIDGET:
                                default: {
                                    break;
                                }
                            }

                            break;
                        }
                        default: {
                            break;
                        }
                    }

                    const renderLazyLoad = lazyLoadEnabled && renderedComponentsCounter > numberOfComponentsToRenderWithoutLazyLoad;

                    return (
                        <Fragment key={`${item.sid || item.type}_${index}`}>
                            {renderLazyLoad ? (
                                <LazyLoad
                                    component={ComponentToRender}
                                    title={item.title}
                                    {...propsToRender}
                                />
                            ) : (
                                <ComponentToRender {...propsToRender} />
                            )}

                            {enableRenderTracking && <PageRenderReport />}
                        </Fragment>
                    );
                })}
            </>
        );
    }
}

ComponentList.propTypes = {
    context: PropTypes.oneOf([CONTEXTS.CONTAINER, CONTEXTS.MODAL]).isRequired,
    enablePageRenderTracking: PropTypes.bool,
    trackingCount: PropTypes.number,
    items: PropTypes.array,
    page: PropTypes.string,
    removeFirstItemMargin: PropTypes.bool,
    removeLastItemMargin: PropTypes.bool
};

ComponentList.defaultProps = {
    enablePageRenderTracking: false,
    trackingCount: 2,
    items: Empty.Array,
    page: null,
    removeFirstItemMargin: null,
    removeLastItemMargin: null
};

export default wrapComponent(ComponentList, 'ComponentList', true);

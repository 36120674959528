export default {
    TYPES: {
        SUPER_LEADERBOARD: {
            NAME: 'SUPER_LEADERBOARD',
            WIDTH: 970,
            HEIGHT: 90,
            DISPLAY: ['none', 'block'],
            SLOT: {
                CATEGORY: '02123',
                SEARCH: '03123'
            }
        },
        MOBILE_LEADERBOARD: {
            NAME: 'MOBILE_LEADERBOARD',
            WIDTH: 320,
            HEIGHT: 50,
            DISPLAY: [null, 'none'],
            SLOT: {
                CATEGORY: '02223',
                SEARCH: '03223'
            }
        },
        WIDE_SIDESCRAPER: {
            NAME: 'WIDE_SIDESCRAPER',
            WIDTH: 160,
            HEIGHT: 600,
            DISPLAY: ['none', null, 'block'],
            SLOT: {
                CATEGORY: '02124',
                SEARCH: '03124'
            }
        }
    }
};

export default function getResource(label, vars = []) {
    const resources = {
        transactionTaxRefunds: 'Tax Refund and Exemption Program',
        taxExemptionCategorySublabel: 'Please select a category.',
        taxExemption: 'Tax Exemption',
        categoryStepTitle: 'Tax Exemption Category',
        categoryStepDisplayName: 'Option to Select',
        categoryStepSubtitle: 'Please choose a category',
        uploadStepTitle: 'Upload Document(s)',
        uploadStepDisplayName: 'Documents',
        orderNumberInputStepTitle: 'Order Number',
        orderNumberInputStepDisplayName: 'Order Number',
        orderNumberInputStepPlaceholder: 'Order #',
        orderTableLabelOrderNumber: 'Order Number',
        orderTableLabelOrderDate: 'Order Date',
        orderTableLabelSelected: 'Selected',
        orderTableSelctionError: 'You have selected the maximum of 10 order for this tax-exemption request. If you wish to select another order, you must first deselect one of the current selections before making a change.',
        taxExemptionInfoStepTitle: 'Tax-Exemption Account Info',
        taxExemptionInfoStepDisplayName: 'Tax Exemption Information',
        additionalCommentsStepTitle: 'Additional Comments',
        additionalCommentsStepDisplayName: 'Additional Comments',
        additionalCommentsStepPlaceholder: 'Please describe your situation here.',
        additionalCommentsStepCopy: 'Please provide any additional information that will help explain your situation. Is there any other information that will help process your tax exemption.',
        finalReviewStepTitle: 'Final Review',
        finalReviewStepDisplayName: 'Final Review',
        finalReviewStepCopy: 'Please review the information you provided above and agree to the Terms of Use and Privacy Policy in order to proceed.',
        finalReviewStepErrorText: 'Please review and agree to the checkboxes below in order to proceed.',
        generalInfo: 'General Info before you begin',
        uploadAction: 'Upload',
        nextAction: 'Next',
        editAction: 'Edit',
        submitAction: 'Submit',
        dateColumnTitle: 'Date',
        maxDocumentsHint: 'Documents (max 3)',
        documentsNote: 'Please note, any incomplete information on your exemption document (signature, date, not checking every applicable box) will result in a request for you to resubmit the document with complete information.',
        documentsContrains: 'Please upload a jpeg/jpg, png, img, or pdf no more than 4 MB.',
        documentsIndications: 'Please upload front and back of the document if the document is double-sided.',
        invalidFile: 'The file could not be uploaded. Please check the file size.',
        missingFreightForwarder: 'Please select one of the following',
        categoryTitleForIA: 'Indigenous American',
        categoryTitleForR: 'Reseller',
        categoryTitleForNPRCO: 'Non-Profit, Religious or Charitable Organizations',
        categoryTitleForDVIFO: 'Disabled Veterans and Immediate Family in Oklahoma',
        categoryTitleForESFFF: 'Export Sale via Florida Freight Forwarder',
        categoryTitleForSLGEI: 'State & Local Government & Educational Institutions',
        categoryTitleForFNM: 'First Nation Members',
        categoryTitleForFA: 'First Nation Members',
        categoryTitleForO: 'Other',
        firstName: 'First Name',
        lastName: 'Last Name',
        email: 'Email',
        startApplication: 'Start Application',
        documentsLabelForIA: 'Please upload your tribal identification',
        documentsLabelForFA: 'Please upload your tribal identification and other supporting documents.',
        documentsLabelForDVIFO: 'Please upload your Sales Tax-Exemption Card, you may be exempt from sales tax on purchases made within the State of Oklahoma.',
        documentsLabelForNPRCO: 'Please upload provide a state-issued letter confirming your tax-exempt status, and/or the state-prescribed exemption certificate, plus your order is shipped to and paid for by the organization.',
        documentsLabelForR: 'Please upload a valid state-prescribed resale certificate for the ship-to state and your order is shipped-to and paid for by your business.',
        documentsLabelForSLGEI: 'Please upload the state-prescribed exemption certificate or other documentation. Upload a confirmation that your order is shipped-to and paid for by the government entity or educational institution.',
        documentsLabelForSLGEI2: 'Please upload a valid exemption certificate for the ship-to state, or a letter from the state designating your government entity or educational institution as tax exempt.',
        documentsLabelForESFFF: 'If your purchase was exported outside of the United States via a Florida Freight Forwarder with proper documentation, your purchase may be tax exempt.',
        documentsLabelForO: 'Please upload tax-exemption document(s) that is applicable to your refund request.',
        firstNameInputLabel: 'First Name',
        lastNameInputLabel: 'Last Name',
        emailInputLabel: 'Email',
        tribeNameInputLabel: 'Tribe Name',
        tribeIdNumberInputLabel: 'Tribe ID Number (if any)',
        reserveNameInputLabel: 'Name of Reserve Where Your Order Was Delivered',
        idCardLabel: 'ID Card',
        issueDateInputLabel: 'Issue Date',
        expirationDateInputLabel: 'Expiration Date',
        expirationDateInvalid: 'Expiration Date must be after the Issue Date',
        none: 'None',
        exemptionNumberLabel: 'Exemption Number',
        exemptionNumberPlaceholder: 'EXM-XXXXXXXX-XX',
        effectiveDateLabel: 'Effective Date',
        effectiveDatePlaceholder: 'MM/DD/YYYY',
        addressSubLabel: 'Address',
        streetAddress: 'Street Address',
        address2Label: 'Door buzzer, building code, APT#, etc',
        zipCode: 'ZIP/Postal Code',
        city: 'City',
        state: 'State/Region',
        addressTitle: 'Address',
        taxAddress1Empty: 'A street address is required. Please enter your street address.',
        taxAddress2Empty: 'An additional address line is required. Please enter your apartment, suite, or other address details.',
        taxCityEmpty: 'A city is required. Please enter your city.',
        taxStateEmpty: 'A state is required. Please select your state.',
        taxZipCodeEmpty: 'A zip code is required. Please enter your zip code.',
        taxZipCodeInvalid: 'A valid zip code is required. Please enter a valid zip code.',
        orderNumberNotFound: 'This order number does not exist. Please try again.',
        taxAdditionalCommentsEmpty: 'Provide additional information that might provide more information as we review your request.',
        registrationNumber: 'Registration Number',
        alias: 'Alias',
        registryGroupNumber: 'Registry Group Number',
        registryBandName: 'Registry Band Name',
        nameOfReservation: 'Name of Reserve Where Your Order Was Delivered',
        datesLabel: 'Dates',
        dateLabelFreightForwarder: 'Certificate',
        registrationNumberEmpty: 'Registration number required. Please enter your registration number.',
        registrationNumberInvalid: 'Please enter a valid registration number.',
        registryGroupEmpty: 'Please enter your registry group number.',
        registryBandNameEmpty: 'Please enter your registry band name.',
        nameOfReservationEmpty: 'Please enter the name of your reservation.',
        dateRangeInvalid: 'Expiration Date must be after the Issue Date.',
        noAdditionalComments: 'None',
        organizationLabel: 'Organization',
        organizationAddressLabel: 'Organization Headquarter Address',
        organizationPositionLabel: 'Your Position within Organization',
        organizationNameLabel: 'Name of Tax-Exempt Organization',
        organizationUrlLabel: 'Organization URL',
        stateIssuedTaxExemptNumberLabel: 'State-Issued Tax-Exempt Number',
        phoneNumberLabel: 'Phone Number',
        creditCardSecondTitle: 'Credit Card Used For Purchase',
        creditCardIssued: 'Was this credit card issued by the Organization?',
        yes: 'Yes',
        no: 'No',
        invalidCreditCardIssued: 'Please make a selection.',
        fixErrorsBelow: 'Please review and fix the errors below.',
        viewPositionLabel: 'Position',
        viewOrganizationNameLabel: 'Organization Name',
        viewOrganizationUrlLabel: 'Organization URL',
        viewStateIssuedTaxExemptNumberLabel: 'State-Issued Tax-Exempt Number',
        viewOrganizationPhoneNumberLabel: 'Organization Phone Number',
        viewHeadquarterAddressLabel: 'Headquarter Address',
        viewNameOnCreditCardLabel: 'Name on Credit Card',
        viewCreditCardIssuedLabel: 'Was this Credit Card Issued by the Organization?',
        freightForwarderNameInputLabel: 'Freight Forwarder Name',
        freightForwarderCertNumberInputLabel: 'Freight Forwarder Certificate Number',
        freightForwarderNameEmpty: 'Freight forwarder name is required. Please enter the freight forwarder name.',
        freightForwarderNameInvalid: 'Freight forwarder name is invalid. Please enter a valid alphanumeric freight forwarder name.',
        freightForwarderCertEmpty: 'Certificate number is required. Please enter the certificate number.',
        freightForwarderCertInvalid: 'Freight forwarder cert is invalid. Please enter a valid alphanumeric freight forwarder name.',
        certIssueDateLabel: 'Certificate Issue Date',
        certExpirationDateLabel: 'Certificate Expiration Date',
        idCardIssueDateLabel: 'ID Card Issue Date',
        idCardExpirationDateLabel: 'ID Card Expiration Date',
        tribeNameLabel: 'Tribe Name',
        tribeIdLabel: 'Tribe ID Number',
        tribeReserveNameLabel: 'Name of Reservation Where Your Order Was Delivered',
        tribeReserveNameEmpty: 'Please enter the reservation.',
        tribeNameEmpty: 'Please enter the tribe name.',
        businessPositionLabel: 'Your Position within Business',
        businessNameLabel: 'Business Name',
        businessUrlLabel: 'Business URL',
        stateSalesTaxPermitNumberLabel: 'State Sales Tax Permit Number',
        businessLabel: 'Business Information',
        businessAddressLabel: 'Business Headquarter Address',
        formSubmittedStepSubtitle: 'We have received your application!',
        formSubmittedStepCopy: 'You have successfully submitted the documents. Application updates will be sent via email in 7 - 10 business days.',
        submissionError: 'Submission Error',
        systemMaintenanceError: 'System Maintenance',
        networkError: 'Network Error',
        sessionExpiredError: 'Session Expired',
        modalButtonTextReview: 'Review',
        documentNotUploaded: 'There was an error with the document. Please review the document type and size.',
        unsupportedDocumentType: 'There was an error with the document. Please review the document type and size.',
        orderNotFound: 'The order number could not be found. Please review the application and submit again.',
        genericTaxApiError: 'A technical error has occurred. Please review the application for any errors and try again.',
        applicationExists: 'If you have recently submitted an application you must wait after the team has finished reviewing that application before you start a new application.',
        unknownError: 'An error has occurred. Please try again or come back another time.',
        viewMore: 'View more',
        viewLess: 'View less',
        select: 'Select',
        deselect: 'Deselect',
        taxExemptAddress: 'Tax-Exempt Address',
        taxExemptAddressSubtitle: 'Tax-Exemption can only be applied to 1 address at this time.',
        clientOrderNotFound: 'Sorry, you cannot start a tax application due to the absence of recent online orders.',
        taxExemptionTitle: 'Would you like to save a tax exemption status to an address?',
        taxExemptionConfirmTitle: 'Yes, I would like to save the tax exempt status address to my profile.',
        taxExemptionConfirmSubtitle: 'Select this option if you would like to save and/or replace the current tax-exemption address to your profile. Once your address is authenticated this address as a valid tax exemption address, you may receive tax exemption for all future orders shipped to this address.',
        taxExemptionDenyTitle: 'No, I only want to request a tax-exemption refund.',
        taxExemptionDenySubtitle: 'Select this option if you want to submit a tax-exemption request, do not want to save address to your profile.',
        taxExemptionMissingSelection: 'Please make a selection.',
        existingAddressLabel: 'Select an existing address',
        addAddressLabel: 'Add shipping address',
        genericError: 'Something went wrong. Please come back and try again later.',
        setDefaultAddress: 'Set this address as default.'
    };

    return resources[label];
}

import cookieUtils from 'utils/Cookies';

const ShoppingListUtils = {
    isSLSServiceEnabled: () => {
        if (Sephora.configurationSettings.isSLSServiceEnabled) {
            if (Sephora.configurationSettings.isSLSServiceCookieIgnore) {
                return true;
            } else {
                return cookieUtils.isRCPSSLSEnabled();
            }
        }

        return false;
    }
};

export default ShoppingListUtils;

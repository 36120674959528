import ufeApi from 'services/api/ufeApi';

const getUserReservations = (token, {
    channel = 'rwd', country = 'US', language = 'en', email, status = 'UPCOMING'
}) => {
    const apiVersion = Sephora.configurationSettings.isRequestAppointmentEnabled ? 'v2' : 'v1';
    const locale = `${language.toLowerCase()}-${country}`;
    const url = `/gway/${apiVersion}/happening/reservations/user/${email}?channel=${channel}&locale=${locale}&status=${status}`;

    return ufeApi
        .makeRequest(
            url,
            {
                method: 'GET',
                headers: {
                    authorization: `Bearer ${token}`
                }
            },
            { isHappening: true }
        )
        .then(res => (res.errorCode || res.errors ? Promise.reject(res) : res));
};

export default getUserReservations;

import ufeApi from 'services/api/ufeApi';
import authUtils from 'utils/Authentication';
import RCPSCookies from 'utils/RCPSCookies';

// https://jira.sephora.com/wiki/display/ILLUMINATE/Auto+Login+API

async function autoLogin(_body = {}) {
    const url = '/api/ssi/autoLogin';
    const headers = {
        'Content-type': 'application/json',
        'x-requested-source': 'web'
    };

    if (RCPSCookies.isRCPSAuthEnabled()) {
        headers.deviceId = await authUtils.getFingerPrint();
    }

    return ufeApi
        .makeRequest(url, {
            method: 'POST',
            credentials: 'include',
            headers,
            body: JSON.stringify(_body)
        })
        .then(data => (data.errorCode ? Promise.reject(data) : data));
}

export default { autoLogin };

/* eslint-disable no-unused-vars */
import ufeApi from 'services/api/ufeApi';

// https://jira.sephora.com/wiki/display/ILLUMINATE/Update+Preview+Settings+API

function updatePreviewSettings(params) {
    const url = '/api/util/previewSettings';

    return ufeApi
        .makeRequest(url, {
            method: 'PUT',
            body: JSON.stringify(params)
        })
        .then(data => (data.errorCode ? Promise.reject(data) : data));
}

export default updatePreviewSettings;

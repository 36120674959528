const PageTemplateType = {
    AddPhoto: 'Community/Gallery/AddPhoto',
    Addresses: 'Community/RichProfile/MyAccount/Addresses',
    AddReviewPage: 'Product/AddReviewPage',
    Affiliates: 'ThirdParty/Affiliates',
    Apply: 'CreditCard/Apply',
    BeautyInsider: 'Community/RichProfile/BeautyInsider',
    BeautyWinPromo: 'ContentStore/BeautyWinPromo',
    BrandNthCategory: 'Brands/BrandNthCategory',
    BrandsList: 'Brands/BrandsList',
    RwdBasket: 'Basket/RwdBasketpage',
    BuyPage: 'BuyPage/RwdBuyPage',
    Category: 'Category/Category',
    Checkout: 'Checkout/Checkout',
    Invoice: 'Invoice/Invoice',
    Community: 'Community/Community',
    Confirmation: 'Checkout/Confirmation',
    Content: 'Content/Content',
    ContentStore: 'ContentStore/ContentStore',
    ContentStoreNoNav: 'ContentStore/ContentStoreNoNav',
    RwdContentStore: 'ContentStore/RwdContentStore',
    Departments: 'SiteMap/Departments',
    EditPlaySubscription: 'Play/EditPlaySubscription',
    EmailPostal: 'Community/RichProfile/MyAccount/EmailPostal',
    EnhancedContent: 'Content/EnhancedContent',
    ExperienceDetails: 'OnlineReservation/ExperienceDetails',
    Gallery: 'Community/LegacyGallery',
    Homepage: 'Homepage/Homepage',
    InstoreServices: 'Community/RichProfile/InstoreServices',
    Lists: 'Community/RichProfile/Lists',
    MyAccount: 'Community/RichProfile/MyAccount',
    NthCategory: 'Category/NthCategory',
    OrderDetails: 'Community/RichProfile/MyAccount/OrderDetails',
    Orders: 'Community/RichProfile/MyAccount/Orders',
    PaymentMethods: 'Community/RichProfile/MyAccount/PaymentMethods',
    TaxClaim: 'Community/RichProfile/MyAccount/TaxClaim',
    PreviewSettings: 'PreviewSettings/PreviewSettings',
    ProductPage: 'Product/ProductPage',
    UnsubscribeQuestion: 'Product/UnsubscribeQuestion',
    SubmitQuestion: 'Product/SubmitQuestion',
    SubmitAnswer: 'Product/SubmitAnswer',
    Profile: 'Community/RichProfile/Profile',
    PurchaseHistory: 'Community/RichProfile/PurchaseHistory',
    Referrer: 'Campaigns/Referrer',
    ResetPassword: 'Community/RichProfile/MyAccount/ResetPassword',
    Search: 'Search/Search',
    RwdBuyPage: 'BuyPage/RwdBuyPage',
    ShoppingList: 'Community/RichProfile/ShoppingList',
    StoreHub: 'OnlineReservation/StoreHub',
    VendorGenericLogin: 'ThirdParty/VendorGenericLogin',
    VendorLogin: 'ThirdParty/VendorLogin',
    AutoReplenishment: 'Community/RichProfile/MyAccount/AutoReplenishment',
    BeautyPreferences: 'BeautyPreferences/BeautyPreferences',
    ReplacementOrderPage: 'Community/RichProfile/MyAccount/ReplacementOrder',
    ReplacementOrderPageStatus: 'Community/RichProfile/MyAccount/ReplacementOrderPageStatus',
    SmartSkinScan: 'SmartSkinScan/SmartSkinScan',
    photoCaptureSmartSkinScan: 'SmartSkinScan/PhotoCapture',
    Rewards: 'Rewards/Rewards',
    GalleryPage: 'Community/GalleryPage',
    MyGalleryPage: 'Community/MyGallery',
    CommunityUserPublicGallery: 'Community/Gallery/UsersPublicGallery',
    Happening: 'Happening/Happening',
    HappeningNonContent: 'Happening/HappeningNonContent',
    RwdTlp: 'Content/TargetedLandingPage',
    RwdCheckout: 'Checkout/RwdCheckout'
};

export default PageTemplateType;

import userUtils from 'utils/User';
import p13nUtils from 'utils/localStorage/P13n';
import anaConsts from 'analytics/constants';
import processEvent from 'analytics/processEvent';
import constants from 'constants/content';
import urlUtils from 'utils/Url';
import testTarget from 'utils/TestTarget';
import Storage from 'utils/localStorage/Storage';
import Empty from 'constants/empty';
import store from 'store/Store';

import { cmsComponentDataSelector } from 'selectors/cmsComponents';

const { getPageType } = testTarget;

const {
    COMPONENT_TYPES: {
        BANNER_LIST, PRODUCT_LIST, RECAP, SOFT_LINKS, PROMOTION_LIST, REWARD_LIST, BANNER
    },
    BANNER_TYPES: { PERSISTENT }
} = constants;

const {
    SOT_P13N_TRACKING_EVENT,
    CMS_REFERER_LOCAL_STORAGE_KEY,
    CMS_COMPONENT_EVENTS: { ITEM_CLICK }
} = anaConsts;

const NA = 'n/a';

const expiry = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);

function fireEventForTagManager(eventName, eventData = Empty.Object) {
    var event = new CustomEvent(eventName, eventData);

    window.dispatchEvent(event);
}

let isHomepage = false;

const saveRefererData = ({ eventData, targetUrl }) => {
    const hasParent = eventData?.personalizationData?.sid !== eventData?.personalizationData?.bannersPersonalizedData?.[0]?.sid;
    const child = eventData?.personalizationData?.bannersPersonalizedData?.[0];
    const currentSid = child?.sid ?? eventData?.personalizationData?.sid ?? null;

    const componentRefData = {
        currentSid,
        targetUrl,
        parent: hasParent
            ? {
                sid: eventData?.personalizationData.sid,
                type: eventData?.personalizationData.contentType,
                sys: {
                    publishedVersion: eventData?.personalizationData.publishedVersion
                }
            }
            : null,
        child: {
            sid: child.sid || null,
            type: !hasParent ? eventData?.personalizationData.contentType : child?.type,
            sys: child?.publishedVersion
                ? {
                    publishedVersion: child.publishedVersion
                }
                : null
        }
    };

    mountComponentRef(componentRefData);
};

export const shouldSentEvent = eventData => {
    const hasPersonalizationData = eventData?.personalizationData.bannersPersonalizedData?.length > 0;

    if (!hasPersonalizationData) {
        return;
    }

    if (eventData?.specificEventName === ITEM_CLICK) {
        const hasParent = eventData?.personalizationData.sid !== eventData?.personalizationData.bannersPersonalizedData[0].sid;
        const child = eventData?.personalizationData.bannersPersonalizedData[0];
        const currentSid = child.sid ?? eventData?.personalizationData.sid ?? null;

        const componentRefData = {
            currentSid,
            parent: hasParent
                ? {
                    sid: eventData?.personalizationData.sid,
                    type: eventData?.personalizationData.contentType,
                    sys: {
                        publishedVersion: eventData?.personalizationData.publishedVersion
                    }
                }
                : null,
            child: {
                sid: child.sid || null,
                type: !hasParent ? eventData?.personalizationData.contentType : child?.type,
                sys: child?.publishedVersion
                    ? {
                        publishedVersion: child.publishedVersion
                    }
                    : null
            }
        };

        mountComponentRef(componentRefData);
    }

    fireEventForTagManager(SOT_P13N_TRACKING_EVENT, {
        detail: {
            data: eventData,
            specificEventName: eventData.specificEventName
        }
    });

    return;
};

// Get the rest of the personalization data from the local storage, by matching the context of
// the banner item with the context of the personalization data.
export const matchContexts = itemPersonalization => {
    const { personalizationData } = getPersonalizationInfo() || Empty.Array;

    if (!itemPersonalization?.context || !personalizationData.length) {
        return null;
    }

    const { context } = itemPersonalization;

    const matchData = personalizationData.find(dataItem => dataItem.context === context);

    return matchData?.p13n || Empty.Object;
};

// Get the personalized item from the local storage, by matching the sid of the item with the sid of the personalization data.
export const personlizedItem = sid => {
    const { personalizationData } = getPersonalizationInfo();

    if (!personalizationData.length) {
        return null;
    }

    const matchData = personalizationData.find(dataItem => dataItem?.variationData?.sid === sid);

    if (!matchData?.variationData) {
        return null;
    }

    return { ...matchData?.variationData, p13n: matchData?.p13n };
};

export const getPersonalizationInfo = () => {
    const personalizationData = p13nUtils.getAllPersonalizedCache();
    const profileId = userUtils.getProfileId();
    const profileStatus = userUtils.getProfileStatus();
    const biAccountId = digitalData?.user?.[0].segment.biAccountId;
    const biStatus = userUtils.getBiStatus();

    return {
        biStatus,
        profileId,
        biAccountId,
        profileStatus,
        personalizationData
    };
};

export const mountComponentEventData = ({ item, component }) => {
    const componentData = {
        sid: item.sid,
        itemIndex: typeof item?.itemIndex === 'number' ? item.itemIndex + 1 : null
    };

    const { personalization } = item;
    componentData.p13n = {};

    if (item?.sys?.publishedVersion) {
        componentData.publishedVersion = item.sys.publishedVersion;
    }

    if (item?.p13n?.context) {
        componentData.p13n = item.p13n;
    } else if (personalization) {
        const personalizationLocalData = matchContexts(personalization);

        if (personalizationLocalData) {
            componentData.p13n = personalizationLocalData;
        }

        const personalizationDataContent = p13nUtils.getPersonalizationCache(personalization.context);

        if (personalizationDataContent?.variationData?.sid) {
            componentData.sid = personalizationDataContent?.variationData?.sid;
            componentData.publishedVersion = personalizationDataContent?.variationData?.sys.publishedVersion;
        }
    }

    switch (component) {
        case BANNER_LIST: {
            break;
        }
        case BANNER: {
            break;
        }

        case RECAP:
        case PROMOTION_LIST: {
            componentData.title = item.title;

            break;
        }

        case REWARD_LIST:
        case PRODUCT_LIST: {
            delete componentData.sid;
            componentData.skuId = item.skuId;

            break;
        }

        case SOFT_LINKS: {
            componentData.label = item.label;
            componentData.type = item.type;

            break;
        }

        default: {
            break;
        }
    }

    return componentData;
};

/**
 * Mounts the event data for the component list, ex: BannerList, RecapList
 * @param {*} items
 * @param {*} eventName
 * @param {*} title
 * @param {*} sid
 * @param {*} clickedSid
 * @param {*} component example: 'BannerList' | 'Recap'
 * @returns
 */
export const mountCmsComponentEventData = ({
    items, eventName, title, sid, component, p13n
}) => {
    const bannersPersonalizedData = [];
    const { biStatus, profileId, biAccountId, profileStatus } = getPersonalizationInfo();

    const personalizationData = {
        title,
        biStatus,
        profileId,
        biAccountId,
        profileStatus,
        sid,
        contentType: component === PERSISTENT ? BANNER : component
    };

    const state = store.getState();
    const cmsContent = cmsComponentDataSelector(state);

    if (Object.keys(cmsContent).length) {
        const pageItems = [...cmsContent.items, ...cmsContent?.innerData];
        const currentSid = eventName === ITEM_CLICK ? items[0]?.sid : sid;
        const currentItem = pageItems.find(item => item.sid === currentSid);

        if (currentItem?.personalization) {
            const personalizationLocalData = matchContexts(currentItem.personalization);

            if (personalizationLocalData) {
                personalizationData.p13n = personalizationLocalData;
            }
        }

        if (currentItem?.sys?.publishedVersion) {
            personalizationData.publishedVersion = currentItem.sys.publishedVersion;
        }
    }

    items.forEach(_item => {
        const variantData = personlizedItem(_item.sid);
        const item = variantData ?? _item;
        const componentData = mountComponentEventData({
            item,
            component
        });

        bannersPersonalizedData.push(componentData);
    });

    personalizationData.bannersPersonalizedData = bannersPersonalizedData;

    if (p13n) {
        personalizationData.p13n = p13n;
    }

    const eventData = {
        linkName: eventName,
        actionInfo: eventName,
        specificEventName: eventName,
        personalizationData,
        title: digitalData?.page?.attributes?.sephoraPageInfo?.pageName || '',
        page: getPageType() || ''
    };

    if (eventName === ITEM_CLICK) {
        if (window?.pageInfo?.path !== window?.previousPageInfo?.path) {
            window.previousPageInfo = window.pageInfo;
        }

        if (isHomepage) {
            const targetUrl = items?.[0]?.targetUrl ?? items?.[0]?.action?.targetUrl ?? '';
            saveRefererData({ eventData, targetUrl });
        }
    }

    return eventData;
};

const FB_COMPONENTS = [PROMOTION_LIST, REWARD_LIST, BANNER, PERSISTENT];

/**
 * Check if the component is a FB component to avoid kill switch
 * @param {*} component
 * @returns
 */
export const isValidFBComponent = async component => {
    const { isEnabledForHomePage } = Sephora?.configurationSettings?.impressionTracker || Empty.Object;

    const { default: Location } = await import('utils/Location');

    isHomepage = Location.isHomepage();

    const isHome = isHomepage && isEnabledForHomePage;
    const isFB = Location.isBasketPage() && FB_COMPONENTS.includes(component);
    const isValidPage = Location.isOffersPage() || Location.isRewardsPage();

    if (isFB || isValidPage || isHome) {
        return true;
    }

    return false;
};
/**
 * Sends the event data for the component list, ex: BannerList, RecapList
 * @param {*} items
 * @param {*} eventName
 * @param {*} title
 * @param {*} sid
 * @param {*} clickedSid
 * @param {*} component example: 'BannerList' | 'Recap'
 * @param {*} p13n
 * @param {*} parentInfo - additional info to be sent in the event data
 * @returns
 */

export async function sendCmsComponentEvent({
    items, eventName, title, sid, clickedSid, component, p13n
}) {
    if (!eventName || typeof sid === 'undefined' || !component || !items?.length) {
        return null;
    }

    const eventData = mountCmsComponentEventData({
        items,
        eventName,
        title,
        sid,
        clickedSid,
        component,
        p13n
    });

    if (eventData) {
        if (component === PERSISTENT) {
            processEvent.process(SOT_P13N_TRACKING_EVENT, { data: eventData });

            return null;
        } else {
            const shouldBeTriggerEvent = await isValidFBComponent(component);

            if (shouldBeTriggerEvent) {
                if (eventName === ITEM_CLICK) {
                    shouldSentEvent(eventData);

                    return null;
                } else {
                    processEvent.process(SOT_P13N_TRACKING_EVENT, { data: eventData });

                    return null;
                }
            }
        }
    }

    return null;
}

export const refererActions = {
    get: () => Storage.local.getItem(CMS_REFERER_LOCAL_STORAGE_KEY),
    set: data => Storage.local.setItem(CMS_REFERER_LOCAL_STORAGE_KEY, data, expiry),
    remove: () => Storage.local.removeItem(CMS_REFERER_LOCAL_STORAGE_KEY)
};

export const checkCmsRefererInfo = () => {
    const referer = urlUtils.getParamValueAsSingleString('referer');
    const cmsRefererInfo = refererActions.get();

    if (referer && cmsRefererInfo) {
        const parent = cmsRefererInfo?.parent.sid || cmsRefererInfo?.child?.sid;

        if (referer !== parent) {
            refererActions.remove();
        }
    }

    return;
};

const cacheHasExpired = function (expiryDate) {
    return Date.parse(expiryDate) < new Date().getTime();
};

/** function to mount the component reference
 * @param {object} currentSid - current component sid
 * @param {string} targetURL - target URL
 * @param {object} parent - parent component
 * @param {object} child - child component
 */

export const mountComponentRef = ({ currentSid, targetUrl, parent, child }) => {
    const currentComponentRef = refererActions.get();

    if (currentComponentRef && cacheHasExpired(currentComponentRef.expiry)) {
        refererActions.remove();
    }

    const currentComponentData = currentComponentRef?.referer?.[currentSid]
        ? currentComponentRef?.referer?.[currentSid]
        : {
            ...currentComponentRef?.referer?.[currentSid],
            parent: {
                type: parent?.type,
                sid: parent?.sid,
                sys: {
                    publishedVersion: parent?.sys?.publishedVersion
                }
            },
            sid: currentSid,
            type: child.type,
            products: [],
            sys: {
                publishedVersion: child?.sys?.publishedVersion
            },
            targetUrl
        };

    const componentRef = {
        products: [],
        ...currentComponentRef,
        currentSid,
        targetUrl,
        referer: {
            ...currentComponentRef?.referer,
            [currentSid]: currentComponentData
        }
    };

    refererActions.set(componentRef);
};

export const removeProductFromReferer = sku => {
    const cmsRefererInfo = refererActions.get();

    if (cmsRefererInfo) {
        const current = cmsRefererInfo.currentSid;

        if (current) {
            refererActions.set({
                ...cmsRefererInfo,
                products: cmsRefererInfo.products.filter(product => product !== sku.skuId),
                referer: {
                    ...cmsRefererInfo.referer,
                    [current]: {
                        ...cmsRefererInfo.referer[current],
                        products: cmsRefererInfo.referer[current].products.filter(product => product !== sku.skuId),
                        removedFromBasket: true
                    }
                }
            });
        }
    }

    return;
};

const createRefererLink = refererItem => {
    const childSid = refererItem.sid || NA;
    const childPublishedVersion = refererItem.sys?.publishedVersion || NA;
    const parentSid = refererItem.parent?.sid || NA;
    const parentPublishedVersion = refererItem.parent?.sys?.publishedVersion || NA;
    const products = refererItem.products.join(',');
    const conteType = refererItem.parent?.type || refererItem.type;

    return `${childSid}:${childPublishedVersion}:${parentSid}:${parentPublishedVersion}:${conteType}=${products}`;
};

const generateRefererLinks = (refererItems, skuIds = []) => {
    const refererLinks = [];

    for (const key in refererItems) {
        if (Object.prototype.hasOwnProperty.call(refererItems, key)) {
            const refererItem = refererItems[key];
            const hasProduct = skuIds.length ? refererItem.products.some(product => skuIds.includes(product)) : true;

            if (hasProduct) {
                const link = createRefererLink(refererItem);
                refererLinks.push(link);

                if (skuIds.length) {
                    refererItem.products = refererItem.products.filter(product => !skuIds.includes(product));
                }
            }
        }
    }

    return refererLinks.join(';');
};

/**
 *  Mounts the referer link by items
 * @param {object} items
 * @returns  referer link
 */
export const mountCmsRefererLinkByItems = ({ items }) => {
    if (!items?.length) {
        return null;
    }

    const dataReferer = refererActions.get();

    if (dataReferer && cacheHasExpired(dataReferer.expiry)) {
        refererActions.remove();

        return null;
    }

    const skuIds = items.map(item => item.sku.skuId);

    if (dataReferer?.products?.length) {
        dataReferer.products = dataReferer.products.filter(product => !skuIds.includes(product));

        const refererLinks = generateRefererLinks(dataReferer.referer, skuIds);

        dataReferer.link = refererLinks;
        refererActions.set(dataReferer);

        return refererLinks;
    }

    return null;
};

export const mountRefererLinkLinK = target => {
    if (!target) {
        return '';
    }

    return `${target.sid}:${target?.sys.publishedVersion};`;
};

export const mountCmsRefererLink = () => {
    const cmsRefererInfo = refererActions.get();

    if (cmsRefererInfo?.products?.length) {
        const refererLinks = generateRefererLinks(cmsRefererInfo.referer);

        refererActions.remove();

        return refererLinks;
    }

    return null;
};

const mountProductReference = (products, sid) => {
    if (!products) {
        return [];
    }

    return !products.includes(sid) ? products.concat(sid) : products;
};

export const addProductToReferer = sku => {
    const cmsRefererInfo = refererActions.get();

    if (cmsRefererInfo?.currentSid?.length) {
        const currentSid = cmsRefererInfo.currentSid;
        const currentData = cmsRefererInfo.referer[currentSid];

        const icid2 = urlUtils.getParamValueAsSingleString('icid2');
        const matchSid = icid2 && currentSid ? icid2.toLocaleLowerCase().trim().includes(currentSid.toLocaleLowerCase().trim()) : false;
        const matchURL = window.location.pathname.includes(currentData.targetUrl.split('?')[0]);

        if (currentSid && (matchURL || matchSid)) {
            refererActions.set({
                ...cmsRefererInfo,
                products: mountProductReference(cmsRefererInfo.products, sku.skuId),
                referer: {
                    ...cmsRefererInfo.referer,
                    [currentSid]: {
                        ...cmsRefererInfo.referer[currentSid],
                        products: mountProductReference(cmsRefererInfo.referer[currentSid].products, sku.skuId),
                        addedToBasket: true
                    }
                }
            });
        }
    }

    return;
};

/** Getters for referer data
 * get the referer link
 * @returns referer link
 */
export const getRefererLink = () => {
    const dataReferer = refererActions.get();

    if (dataReferer) {
        return dataReferer.link;
    }

    return null;
};

/**
 * get the current referer sid
 * @returns currentSid
 */
export const getCurrrentReferer = () => {
    const dataReferer = refererActions.get();

    if (dataReferer) {
        return dataReferer.currentSid;
    }

    return null;
};

/**
 * get the current referer sid
 * @returns currentSid
 */
export const getMatchReferer = () => {
    const { currentSid, targetUrl } = refererActions.get() ?? Empty.Object;

    if (!currentSid || !targetUrl) {
        return null;
    }

    const icid2 = urlUtils.getParamValueAsSingleString('icid2');

    const matchSid = icid2 && currentSid ? icid2.toLocaleLowerCase().trim().includes(currentSid.toLocaleLowerCase().trim()) : false;

    const pagePath = window.pageInfo?.pagePath ?? window.location.pathname ?? '';

    const matchPagePath = targetUrl.includes(pagePath);

    if (!matchSid || !matchPagePath) {
        refererActions.set({
            ...refererActions.get(),
            currentSid: '',
            pagePath: ''
        });

        return null;
    }

    return currentSid;
};

import anaConsts from 'analytics/constants';
import processEvent from 'analytics/processEvent';

const { ASYNC_PAGE_LOAD } = anaConsts;

class RewardFulfillmentMethodModalBindings {
    static triggerAnalytics = (type, analyticsData) => {
        const payload = {
            data: { ...analyticsData }
        };
        processEvent.process(type, payload);
    };

    static pageLoad = () => {
        const anaData = {
            pageName: anaConsts.PAGE_NAMES.REWARD_FULFILLMENT_METHOD_MODAL
        };

        RewardFulfillmentMethodModalBindings.triggerAnalytics(ASYNC_PAGE_LOAD, anaData);
    };
}

export default RewardFulfillmentMethodModalBindings;

/* eslint-disable camelcase */

import localeUtils from 'utils/LanguageLocale';
import RMN_BANNER_TYPES from 'components/Rmn/constants';
import * as sponsoredProductsConstants from 'constants/sponsoredProducts';
import urlUtils from 'utils/Url';
import cookieUtils from 'utils/Cookies';
import LOCAL_STORAGE from 'utils/localStorage/Constants';
import Storage from 'utils/localStorage/Storage';
import languageLocalUtils from 'utils/LanguageLocale';
import sponsoredProducts from 'services/api/sponsoredProducts/sponsoredProducts';
const { isCanada, isFrench, isFRCanada, LANGUAGES } = localeUtils;
import store from 'store/Store';

import { cmsRMNBannerSelector } from 'selectors/rmnBanners';
import { updateRmnMainBanners } from 'actions/RmnBannersActions';

const RMN_SOURCES = {
    PDP: 'web-product-page',
    HOMEPAGE: 'web-homepage'
};

const { TYPES } = RMN_BANNER_TYPES;

// Clears any information previously added to the digitalData object
const resetBanners = () => {
    digitalData.page.category.sponsoredProductBanners = [];
    store.dispatch(updateRmnMainBanners(null));
};

// Adds the banner tracking information to the digitalData object
const addBannerToDigitalData = bannerToAdd => {
    const isMobile = Sephora.isMobile();
    let addBannerTrackingInfo = false;

    const BannerType = RMN_BANNER_TYPES.TYPES.MOBILE_LEADERBOARD.NAME;

    // Checks if the mobile version is showing to add the right tracking data
    if (isMobile && bannerToAdd.type === BannerType) {
        addBannerTrackingInfo = true;
    }

    // Checks if the desktop version is showing to add the right tracking data
    if (!isMobile && bannerToAdd.type !== BannerType) {
        addBannerTrackingInfo = true;
    }

    // Verifies the banner information needs to be added to the digitalData object
    if (addBannerTrackingInfo) {
        // Clears any previous banner for that slot and section
        digitalData.page.category.sponsoredProductBanners = digitalData.page.category.sponsoredProductBanners?.filter(
            banner => banner.section !== bannerToAdd.section
        );

        digitalData.page.category.sponsoredProductBanners.push(bannerToAdd);
    }
};

const getTopRefinementValue = refinement => {
    let count = 0;
    let name = '';

    refinement?.values?.forEach(refinementValue => {
        if (refinementValue.count > count) {
            count = refinementValue.count;
            name = refinementValue.refinementValueDisplayName;
        }
    });

    return name;
};

const attributesList = [
    'Concerns',
    'Ingredient Preferences',
    'Formulation',
    'Skin Type',
    'Shopping Preferences',
    'Color Family',
    'Age Range',
    'Finish',
    'Type',
    'Coverage',
    'Fragrance Family',
    'Size',
    'Benefits',
    'Sun Protections',
    'Fragrance Type',
    'Hair Type',
    'Skin Type',
    'Age Range'
];

const getAttributes = catalog => {
    const attributes = {};

    catalog?.refinements?.forEach(refinement => {
        if (attributesList.includes(refinement.displayName)) {
            attributes[refinement.displayName] = getTopRefinementValue(refinement);
        }
    });

    return attributes;
};

const formatSponsoredProductsClickTracker = product => {
    if (product.sponsored) {
        // Extracts the Click Tracker Id
        product[sponsoredProductsConstants.CLICK_TRACKER_ID_FIELD] = urlUtils.getParamsByName(
            sponsoredProductsConstants.CLICK_TRACKER_ID_API_FIELD,
            product?.click_tracker || null
        );
        product[sponsoredProductsConstants.CLICK_TRACKER_ID_FIELD] =
            Array.isArray(product.click_id) && product.click_id.length > 0 ? product.click_id[0] : '';

        // Extracts the Click Tracker Payload
        product[sponsoredProductsConstants.CLICK_TRACKER_PAYLOAD_FIELD] = urlUtils.getParamsByName(
            sponsoredProductsConstants.CLICK_TRACKER_PAYLOAD_API_FIELD,
            product?.click_tracker || null
        );
        product[sponsoredProductsConstants.CLICK_TRACKER_PAYLOAD_FIELD] =
            Array.isArray(product.click_payload) && product.click_payload.length > 0 ? product.click_payload[0] : '';
    }

    return product;
};

const transformSponsoredProductsResponse = response => {
    const localeLang = localeUtils.getCurrentLanguage().toLowerCase();

    // Converts the response into an array of products cleaning up any non product lines and products without language.
    const productsArray = !response ? [] : Object.values(response).filter(r => r?.product && r?.product[localeLang]);

    // Cleans up the products we are receiving from the Ad service.
    const cleanedUpProducts = productsArray.map(productList => {
        // Builds the products in the product grid format
        const { product, ...trackerInfo } = productList;

        const cleanProduct = {
            // Extracts the product main information taking in count the issue of products without language
            ...productList?.product[localeLang],
            // Adds the tracker information
            ...trackerInfo
        };

        return formatSponsoredProductsClickTracker(cleanProduct);
    });

    return cleanedUpProducts;
};

// Based on the material provided, builds the slot id according to the paratemers provided
const getSlotId = pageType => {
    const country = languageLocalUtils.isUS() ? sponsoredProductsConstants.COUNTRIES.US : sponsoredProductsConstants.COUNTRIES.CA;
    const channel = Sephora.isDesktop() ? sponsoredProductsConstants.CHANNEL.DESKTOP : sponsoredProductsConstants.CHANNEL.MOBILE_WEB;
    const countryCol = country === 'US' ? sponsoredProductsConstants.SLOT_COUNTRY.US : sponsoredProductsConstants.SLOT_COUNTRY.CA;
    const channelCol = channel === 'Desktop' ? sponsoredProductsConstants.SLOT_CHANNEL.DESKTOP : sponsoredProductsConstants.SLOT_CHANNEL.MOBILE_WEB;
    const pageTypeColA =
        pageType === 'Search'
            ? sponsoredProductsConstants.SLOT_PAGETYPE.SEARCH
            : pageType === 'PDP'
                ? sponsoredProductsConstants.SLOT_PAGETYPE.PRODUCT_DETAIL_PAGE
                : sponsoredProductsConstants.SLOT_PAGETYPE.BROWSE;
    const pageTypeColB =
        pageType === 'PDP'
            ? sponsoredProductsConstants.SLOT_PAGETYPE_SECOND_POSITION.PRODUCT_DETAIL_PAGE
            : pageType === 'Search'
                ? sponsoredProductsConstants.SLOT_PAGETYPE_SECOND_POSITION.SEARCH
                : sponsoredProductsConstants.SLOT_PAGETYPE_SECOND_POSITION.BROWSE;

    return `${countryCol}${pageTypeColA}${channelCol}${pageTypeColB}`;
};

const getAdServiceParams = pageType => {
    const userData = Storage.local.getItem(LOCAL_STORAGE.USER_DATA, false, false, true);
    const biAccountId = userData?.data?.profile?.beautyInsiderAccount?.biAccountId || '';
    const session = cookieUtils.read('SephSession') || '';
    const adSvcSlot = getSlotId(pageType);

    return {
        callAdSvc: true,
        adSvcSlot,
        adSvcSession: session,
        ...(biAccountId && { adSvcUser: biAccountId })
    };
};

const getBannerFallback = type => {
    const width = TYPES[type].WIDTH;
    const height = TYPES[type].HEIGHT;
    const canada = isCanada();
    const french = isFrench();
    const canadaFrench = isFRCanada();
    const imageFile = canadaFrench ? 'jpg' : 'png';
    const bannerType = canadaFrench ? '_FR' : '';
    const asset_url = `/img/ufe/rmn-fallback-${width}x${height}${bannerType}.${imageFile}`;
    const canadianLanguage = french ? LANGUAGES.FR : LANGUAGES.EN;
    const urlprefix = canada ? `/ca/${canadianLanguage.toLowerCase()}` : '';
    const clickthru = `${urlprefix}/beauty/giftcards`;

    return {
        asset_url,
        clickthru
    };
};

const getBannersData = ({
    type, slot, targets, count = 1, countFill = 1, hasFallback = true
}) => {
    const requestParams = {
        width: TYPES[type].WIDTH,
        height: TYPES[type].HEIGHT,
        targets,
        slot,
        count,
        count_fill: countFill
    };

    const fallback = getBannerFallback(type);

    const fallBackArray = Array(count).fill(fallback);

    return sponsoredProducts(requestParams)
        .then(response => {
            if (response.responseStatus === 200) {
                delete response.responseStatus;

                return { data: response, fallback };
            }

            if (hasFallback) {
                return fallBackArray;
            }

            return null;
        })
        .catch(() => {
            return hasFallback ? fallBackArray : {};
        });
};

/**
 * Initialize the banners for the page
 * @param {Object} options
 * @param {String} options.type - type of banner
 * @param {String} options.slot - slot id
 * @param {String} options.targets - target id
 * @param {Number} options.count - number of banners to fetch
 * @param {Number} options.countFill - number of banners to fill
 * @param {Boolean} options.hasFallback - flag to determine if the fallback banner should be used
 */
const initializeBanners = async ({
    type, slot, targets, count = 1, countFill = 1, hasFallback = true
}) => {
    const state = store.getState();
    const rmnBanners = cmsRMNBannerSelector(state);

    if (rmnBanners?.targets !== targets && !rmnBanners?.banners?.length) {
        const banners = await getBannersData({
            type,
            slot,
            targets,
            count,
            countFill,
            hasFallback
        });

        store.dispatch(
            updateRmnMainBanners({
                [slot]: {
                    targets,
                    banners: banners.data,
                    fallback: banners.fallback || banners
                }
            })
        );

        return banners.data;
    }

    return null;
};

export default {
    resetBanners,
    addBannerToDigitalData,
    getAttributes,
    transformSponsoredProductsResponse,
    getAdServiceParams,
    formatSponsoredProductsClickTracker,
    initializeBanners,
    getBannerFallback,
    getSlotId,
    RMN_SOURCES
};

/* eslint-disable class-methods-use-this */
import React from 'react';
import PropTypes from 'prop-types';
import { wrapFunctionalComponent } from 'utils/framework';
import {
    Text, Box, Grid, Link
} from 'components/ui';
import ProductImage from 'components/Product/ProductImage/ProductImage';
import { colors } from 'style/config';
import { getImageAltText } from 'utils/Accessibility';
import skuUtils from 'utils/Sku';
import LocaleUtils from 'utils/LanguageLocale';
const getText = LocaleUtils.getLocaleResourceFile('components/ItemSubstitution/SubstituteItem/locales', 'SubstituteItem');

function PriceSizeAndColor({ item }) {
    const price = item.salePrice || item.listPrice;
    const size = item.size ? `  •  ${getText('size')} ${item.size}` : '';
    const color = item.variationType === skuUtils.skuVariationType.COLOR ? `  •  ${getText('color')}: ${item.variationValue}` : '';

    return (
        <Text
            is='p'
            fontSize='xs'
            color={colors.gray}
        >
            <Text fontWeight='bold'>{price}</Text>
            {size}
            {color}
        </Text>
    );
}

function SubstituteItem({ item, isBasket, doNotSubstitute, onClickHandler }) {
    return (
        <Grid
            marginTop={isBasket ? 2 : 4}
            marginBottom={!isBasket && 1}
            paddingTop={2}
            gap={2}
            columns='auto 1fr auto'
            lineHeight='tight'
            borderRadius={!isBasket && 2}
            backgroundColor='nearWhite'
            paddingBottom={!isBasket && 2}
            paddingX={!isBasket && 3}
        >
            {doNotSubstitute ? (
                <Text
                    fontSize='sm'
                    fontWeight='bold'
                >
                    {getText('doNotSubstitute')}
                </Text>
            ) : (
                <>
                    <Box
                        is='div'
                        display='inline'
                        position='relative'
                    >
                        <ProductImage
                            id={item.skuId}
                            size={36}
                            skuImages={item.skuImages}
                            altText={getImageAltText(item)}
                        />
                    </Box>
                    <Box>
                        <Text
                            fontSize='sm'
                            is='p'
                        >
                            {!isBasket && !item.isSubstituted && !item.isOutOfStock && (
                                <span css={{ fontWeight: 'bold' }}>{getText('substituteWith')}</span>
                            )}
                            {!isBasket && item.isOutOfStock && <span css={{ fontWeight: 'bold' }}>{getText('oufOfStock')}</span>}
                            {item.brandName} {item.productName}
                        </Text>
                        <PriceSizeAndColor item={item} />
                    </Box>
                    {isBasket && (
                        <Link
                            fontSize='sm'
                            color={colors.blue}
                            children={getText('edit')}
                            padding={2}
                            margin={-2}
                            onClick={onClickHandler}
                        />
                    )}
                </>
            )}
        </Grid>
    );
}

SubstituteItem.propTypes = {
    item: PropTypes.shape({}).isRequired,
    isBasket: PropTypes.bool
};

SubstituteItem.defaultProps = {
    item: {},
    isBasket: false
};

export default wrapFunctionalComponent(SubstituteItem, 'SubstituteItem');

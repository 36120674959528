import ufeApi from 'services/api/ufeApi';

const getUserPaymentInfo = (token, { creditCardId = '' }) => {
    const url = `/gway/v1/happening/payment/${creditCardId}`;

    return ufeApi
        .makeRequest(
            url,
            {
                method: 'GET',
                headers: {
                    authorization: `Bearer ${token}`
                }
            },
            { isHappening: true }
        )
        .then(res => (res.errorCode ? Promise.reject(res) : res));
};

export default getUserPaymentInfo;

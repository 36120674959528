/* eslint-disable class-methods-use-this */
import React from 'react';
import { wrapComponent } from 'utils/framework';

import {
    Text, Flex, Box, Link
} from 'components/ui';

import BaseClass from 'components/BaseClass';
import DetailsWrapper from 'components/Content/Happening/ReservationDetails/DetailsWrapper';
import ActionButtons from 'components/Content/Happening/ActionButtons';
import { SERVICE_BUTTONS } from 'components/Content/Happening/ActionButtons/constants';
import PaymentMethodsInfo from 'components/Content/Happening/ReservationDetails/PaymentMethodsInfo';

import FormValidator from 'utils/FormValidator';
import localeUtils from 'utils/LanguageLocale';
import dayUtils from 'utils/Date';
import urlUtils from 'utils/Url';
import { ensureSephoraPrefix } from 'utils/happening';
import {
    isUpcoming, isPast, isCancelled, mergePropsWithItems
} from 'utils/happeningReservation';

import HappeningBindings from 'analytics/bindingMethods/components/Content/Happening/HappeningBindings';

import { mediaQueries } from 'style/config';

import sdnApi from 'services/api/sdn';

import { EXPERIENCES } from 'components/Content/Happening/ReservationDetails/constants';

const { getLocaleResourceFile } = localeUtils;

class ServiceReservationDetails extends BaseClass {
    constructor(props) {
        super(props);

        this.state = {
            paymentCreditCardInfo: {}
        };
    }

    getPaymentInfo() {
        const {
            reservationDetails: {
                paymentInfo: { authPaymentMethodId }
            },
            user: { profileId }
        } = this.props;

        sdnApi.getPaymentOptions(profileId).then(response => {
            const creditCard = response.creditCards.find(card => card.creditCardId === authPaymentMethodId);

            if (creditCard) {
                this.setState({ paymentCreditCardInfo: creditCard });
            }
        });
    }

    getCalendarInfo = () => {
        const { reservationDetails } = this.props;

        const {
            confirmationNumber,
            startDateTime,
            serviceInfo: { duration, displayName: activityName, type },
            store: { displayName: storeName }
        } = reservationDetails;

        return {
            activityName,
            storeName,
            confirmationNumber,
            startDateTime,
            duration,
            type
        };
    };

    renderButtons = () => {
        const { reservationDetails } = this.props;

        if (isUpcoming(reservationDetails.status)) {
            return SERVICE_BUTTONS.UPCOMING;
        }

        if (isPast(reservationDetails.status)) {
            return SERVICE_BUTTONS.PAST;
        }

        if (
            dayUtils.isDayAfter(new Date(), new Date(reservationDetails.startDateTime.replace('[UTC]', ''))) &&
            !isCancelled(reservationDetails.status)
        ) {
            return SERVICE_BUTTONS.IS_DAY_AFTER;
        }

        if (isCancelled(reservationDetails.status)) {
            return mergePropsWithItems(SERVICE_BUTTONS.CANCELLED, { css: styles.singleButton });
        }

        return [];
    };

    redirectToStoreLocation = () => {
        const { reservationDetails } = this.props;
        const { address } = reservationDetails.store;

        urlUtils.openLinkInNewTab(urlUtils.getDirectionsUrl(address));

        return false;
    };

    getWrapperProps = () => ({
        flexWrap: 'wrap',
        width: ['100%']
    });

    componentDidMount() {
        const { reservationDetails } = this.props;
        const { status, store, serviceInfo } = reservationDetails;

        if (!isPast(status)) {
            this.getPaymentInfo();
        }

        HappeningBindings.serviceReservationDetailsPageLoadAnalytics(serviceInfo.displayName, store.storeId, isCancelled(status));
    }

    render() {
        const { reservationDetails, user } = this.props;
        const {
            status,
            startDateTime,
            store,
            confirmationNumber,
            statusDisplayName,
            serviceInfo,
            clientInfo,
            subStatus,
            paymentInfo = {},
            specialRequests,
            selectedFeature,
            artist
        } = reservationDetails;
        const getText = getLocaleResourceFile('components/Content/Happening/ReservationDetails/ServiceDetails/locales', 'ServiceDetails');

        const specialRequestLabel = specialRequests ? 'specialRequests' : 'notProvided';

        const { paymentCreditCardInfo } = this.state;

        const showPaymentInfo = paymentCreditCardInfo?.cardType || isPast(status) || paymentInfo?.refund;

        if (!user.isSignedIn) {
            return null;
        }

        return (
            <DetailsWrapper
                details={{
                    status,
                    type: serviceInfo?.type || EXPERIENCES.SERVICES,
                    serviceFees: paymentInfo?.serviceFees,
                    startDateTime,
                    statusDisplayName,
                    store,
                    subStatus,
                    duration: serviceInfo.durationInt,
                    imageUrl: serviceInfo.imageUrl,
                    description: serviceInfo.description,
                    experienceType: 'service'
                }}
            >
                <>
                    <Flex
                        gap={4}
                        marginBottom={3}
                    >
                        <Box width={105}>
                            <Text
                                is={'p'}
                                fontWeight={'bold'}
                                lineHeight={'18px'}
                                children={getText('service')}
                            />
                        </Box>
                        <Box width={[215, null, null]}>
                            <Text
                                is={'p'}
                                lineHeight={'18px'}
                                children={serviceInfo.displayName}
                            />
                            <Text
                                is={'p'}
                                lineHeight={'18px'}
                            >
                                <Text
                                    lineHeight={'18px'}
                                    children={getText('artist')}
                                />
                                <Text
                                    lineHeight={'18px'}
                                    children={artist?.displayName ?? getText('noArtist')}
                                />
                            </Text>
                            <Text is={'p'}>
                                <Text
                                    is={'span'}
                                    fontWeight={'bold'}
                                    lineHeight={'18px'}
                                >
                                    {serviceInfo.price}
                                </Text>
                                <Text
                                    lineHeight={'18px'}
                                    children={` / ${serviceInfo.duration}`}
                                />
                            </Text>
                        </Box>
                    </Flex>
                    <Flex
                        gap={4}
                        marginBottom={3}
                    >
                        <Box width={105}>
                            <Text
                                is={'p'}
                                fontWeight={'bold'}
                                lineHeight={'18px'}
                                children={getText('details')}
                            />
                        </Box>
                        <Box width={[215, null, null]}>
                            {selectedFeature && (
                                <Text
                                    is={'p'}
                                    lineHeight={'18px'}
                                    children={getText('selectedFeature', [selectedFeature])}
                                />
                            )}
                            <Text
                                is={'p'}
                                lineHeight={'18px'}
                                children={getText(specialRequestLabel, [specialRequests])}
                            />
                        </Box>
                    </Flex>
                    <Flex
                        gap={4}
                        marginBottom={3}
                    >
                        <Box width={105}>
                            <Text
                                is={'p'}
                                fontWeight={'bold'}
                                lineHeight={'18px'}
                                children={getText('location')}
                            />
                        </Box>
                        <Box>
                            <Text
                                is={'p'}
                                lineHeight={'18px'}
                                children={ensureSephoraPrefix(store.displayName)}
                            />

                            <Link
                                color='blue'
                                onClick={this.redirectToStoreLocation}
                            >
                                <Text
                                    display='block'
                                    marginTop={1}
                                    lineHeight={'18px'}
                                    children={getText('getDirections')}
                                />
                            </Link>
                        </Box>
                    </Flex>
                    {showPaymentInfo && (
                        <PaymentMethodsInfo
                            paymentInfo={paymentInfo}
                            status={status}
                            subStatus={subStatus}
                            paymentCreditCardInfo={paymentCreditCardInfo}
                        />
                    )}
                    <Flex
                        gap={4}
                        marginBottom={3}
                    >
                        <Box width={105}>
                            <Text
                                is={'p'}
                                fontWeight={'bold'}
                                lineHeight={'18px'}
                                children={getText('confirmationNumber')}
                            />
                        </Box>
                        <Box width={[215, null, null]}>
                            <Text
                                is={'p'}
                                lineHeight={'18px'}
                                children={confirmationNumber}
                            />
                        </Box>
                    </Flex>
                    {clientInfo && (
                        <Flex
                            alignItems={'center'}
                            marginBottom={4}
                            flexWrap={'wrap'}
                            gap={2}
                        >
                            <Text
                                is={'p'}
                                lineHeight={'18px'}
                                children={`${clientInfo.name}`}
                                css={{
                                    textTransform: 'capitalize'
                                }}
                            />
                            <Text
                                is={'p'}
                                lineHeight={'18px'}
                                children={'•'}
                            />
                            <Text
                                is={'p'}
                                lineHeight={'18px'}
                                children={`${FormValidator.getFormattedPhoneNumber(clientInfo.phoneNumber)}`}
                            />
                            <Text
                                is={'p'}
                                lineHeight={'18px'}
                                children={'•'}
                            />
                            <Text
                                is={'p'}
                                lineHeight={'18px'}
                                children={`${clientInfo.email}`}
                            />
                        </Flex>
                    )}
                    <ActionButtons
                        calendarInfo={this.getCalendarInfo()}
                        reservationDetails={{ ...reservationDetails, serviceFees: paymentInfo?.serviceFees || {} }}
                        buttons={this.renderButtons()}
                        commonButtonProps={{
                            width: [175, null, null],
                            minWidth: [175, null, null]
                        }}
                        wrapperProps={this.getWrapperProps()}
                    />
                </>
            </DetailsWrapper>
        );
    }
}

const styles = {
    singleButton: {
        flex: 0.5,
        [mediaQueries.smMax]: {
            flex: 1
        }
    }
};

ServiceReservationDetails.defaultProps = {
    reservationDetails: {}
};

export default wrapComponent(ServiceReservationDetails, 'ServiceReservationDetails');

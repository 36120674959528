export default function getResource(label, vars = []) {
    const resources = {
        clientDetails: 'Client Details',
        firstName: 'First Name',
        lastName: 'Last Name',
        emailAddress: 'Email Address',
        textNotifications: 'Text Notifications',
        phoneNumber: 'Phone Number',
        smsReminder: 'Yes, please send me text message reminders about my appointment.',
        textNotificationsAgreement: `I agree to the ${vars[0]} and authorize Sephora to use an automated system, an autodialer, an automated system for the selection and/or dialing of telephone numbers, an automatic telephone dialing system (“ATDS”), and/or any other type of system, software, hardware, or machine (no matter how it may be classified) that may use an automated procedure or process for sending text messages regarding my appointment to the mobile number provided. Consent is not a condition of purchase. Message & data rates may apply. See our ${vars[1]}.`,
        textTerms: 'TEXT TERMS',
        privacyPolicy: 'Privacy Policy',
        bookNow: 'Book Now',
        worCheckboxText: `By checking the box, I acknowledge I have carefully read and fully understand all provisions of the ${vars[0]}, and freely and knowingly assume the risk and waive my rights as described.`,
        worLinkText: 'Waiver of Rights and Assumption of Risk',
        worMinorCheckboxText: 'I am booking for someone under the age of 18.',
        worParentCheckboxText: `By checking the box, I acknowledge I am the parent or legal guardian of a minor who is receiving Sephora’s services. I acknowledge I have carefully read and fully understand all provisions of the ${vars[0]}, and freely and knowingly assume the risk and waive my rights as described.`,
        validCreditCard: `To hold this reservation, a valid credit card is required. Full payment, including taxes, will be made in-store using your preferred method. Gratuity can be added after the service. By placing this order, I agree to the ${vars[0]}.`,
        iAcknowledge: `I acknowledge and accept the ${vars[0]}, ${vars[1]}, and ${vars[2]}.`,
        paymentPolicy: 'Payment Policy',
        onTimePolicy: 'On Time Policy',
        cancelationPolicy: 'Cancelation Policy',
        defaultMethod: 'Default Method',
        securityCode: 'Security Code',
        weRequireAWaiver: 'We Require A Waiver Of Rights',
        chooseOne: 'Choose One',
        waiverCheckboxesErrorText: 'To book, please acknowledge and check the appropriate option below.',
        policiesCheckboxErrorText: 'To book, please acknowledge and accept the Policies below.',
        confirm: 'Confirm',
        payment: 'Payment',
        change: 'Change',
        invalidPhoneNumberError: 'Please enter a valid mobile phone number.',
        phoneInfoText: 'In case we need to contact you about your service.',
        securityCodeInputError: 'Security code is incorrect',
        deleteCard: 'Delete card',
        removeCardMessage: 'Are you sure want to remove this card?',
        yes: 'Yes',
        no: 'No',
        genericBookingApiError: 'Your booking request could not be completed at this time. Please try again later.',
        timeSlotAlreadyTakenErr: 'We\'re sorry, but it looks like someone else has just booked the time slot you selected. This can occasionally happen when another client completes their booking while you were making your selection.',
        securityCodeNotConfirmed: 'Please tap “Confirm” to proceed with booking.',
        cardNotSelectedErr: 'Please tap “Use This Card” to proceed with booking.',
        initialDataLoadError: 'We could not fetch any credit card data at this time. Please try again later.',
        timeSlotUnavailable: 'Time Slot Unavailable',
        selectNewTimeSlot: 'Select A New Time Slot',
        worModalTitle: 'Waiver of Rights',
        timeSlotAlreadyTaken: 'We\'re sorry, but it looks like someone else has just booked the time slot you selected. This can occasionally happen when another client completes their booking while you were making your selection.',
        whatYouCanDo: 'What you can do',
        chooseAnotherTime: 'Choose Another Time',
        returnToSchedule: 'Click "Select A New Time Slot" to return to the schedule and pick a new time that works for you.',
        joinWaitlist: 'Join the Waitlist',
        joinWaitlistInfo: 'If there is no availability, click "Join Waitlist" in your preferred time range and we\'ll email you if availability opens due to cancelations.',
        declineAndCancelWaitlist: 'Decline and Cancel Waitlist'
    };

    return resources[label];
}

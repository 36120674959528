import ufeApi from 'services/api/ufeApi';
import headerUtils from 'utils/Headers';
import RCPSCookies from 'utils/RCPSCookies';
import authUtils from 'utils/Authentication';

const { userXTimestampHeader } = headerUtils;

// https://jira.sephora.com/wiki/display/ILLUMINATE/Create+User+API

export async function createUser(profileData) {
    const url = '/api/users/profile';
    const { headerValue, ...args } = profileData;
    const headers = {
        'x-requested-source': 'web',
        ...(headerValue && { headerValue }),
        ...userXTimestampHeader()
    };

    if (RCPSCookies.isRCPSAuthEnabled()) {
        headers.deviceId = await authUtils.getFingerPrint();
    }

    return ufeApi
        .makeRequest(url, {
            method: 'POST',
            headers,
            body: JSON.stringify(args)
        })
        .then(data => (data.errorCode ? Promise.reject(data) : data));
}

export default { createUser };

export default function getResource(label, vars = []) {
    const resources = {
        savedAddresses: 'Adresses enregistrées',
        defaultShippingAddress: 'Adresse d’expédition par défaut',
        setAsDefaultAddress: 'Définir en tant qu’adresse par défaut',
        edit: 'Modifier',
        delete: 'Delete',
        addShippingAddress: 'Ajouter une adresse de livraison',
        title: 'Supprimer l’adresse',
        message: 'Vous avez dépassé le nombre maximal d’adresses sauvegardées. Veuillez en supprimer une et en ajouter une autre à nouveau.',
        buttonText: 'Continuer',
        taxExemptAddressLabel: 'Tax-Exempt Address',
        deleteTaxExemptAddresModalTitle: 'Delete Address?',
        deleteTaxExemptAddresModalMessage: 'If you delete this address, you will also delete your tax exemption status and will need to reapply for another tax-exemption.',
        yes: 'Yes',
        no: 'No'
    };
    return resources[label];
}

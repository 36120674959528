import { SET_RMN_BANNERS_MAIN } from 'constants/actionTypes/rmnBanners';

const initialState = {
    rmnBanners: null
};

const reducer = function (state = initialState, action) {
    switch (action.type) {
        case SET_RMN_BANNERS_MAIN:
            return {
                ...state,
                rmnBanners: {
                    ...action.rmnBanners,
                    ...action.payload.rmnBanners
                }
            };
        default:
            return state;
    }
};

reducer.ACTION_TYPES = { SET_RMN_BANNERS_MAIN };

export default reducer;
